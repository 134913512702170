"use client";
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useRouter } from "next/navigation";
import { Spotlight } from "components/ui/spotlight";
import { RainbowButton } from "components/ui/rainbow-button";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaUser } from "react-icons/fa";

export const HeroSection = () => {
  const [index, setIndex] = useState(0);
  const texts = ["Easy", "Better", "Safer"];
  const router = useRouter();

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [texts.length]);

  // Ensure page always loads at the top
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on page load
  }, []);

  const cardHoverEffect = {
    hover: {
      rotateY: 5,
      rotateX: 5,
      skewX: -1,
      skewY: -1,
      translateY: -4,
      boxShadow: "0px 10px 40px rgba(0, 0, 0, 1)",
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    initial: {
      skewX: 0,
      skewY: 0,
      translateY: 0,
      boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.5)",
    },
  };

  const floatEffect = {
    hover: {
      y: -10,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
      },
    },
    initial: {
      y: 0,
    },
  };

  const contentVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 15,
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <section className="relative flex min-h-screen w-full items-center justify-center overflow-hidden bg-black">
      {/* Spotlight Component */}
      <Spotlight className="absolute inset-0" fill="#4ade80" />

      <div className="pointer-events-none absolute inset-0 select-none bg-black">
        <svg
          className="absolute inset-0 size-full"
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
        >
          <defs>
            <pattern
              id="grid"
              width="40"
              height="40"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 40 0 L 0 0 0 40"
                fill="none"
                stroke="rgba(255, 255, 255, 0.05)"
                strokeWidth="1"
              />
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
        <div
          className="pointer-events-none absolute inset-0"
          style={{
            backgroundImage: "linear-gradient(transparent, black 98%)",
          }}
        ></div>
      </div>

      <motion.div
        className="relative z-10 mx-auto grid w-full max-w-6xl grid-cols-1 gap-14 px-8 pt-32 md:grid-cols-2 md:pt-0"
        variants={contentVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Left Side: Text */}
        <div className="text-center md:text-left">
          <p className="mb-2 text-sm font-bold tracking-widest text-gray-400">
            500+ REGISTERED USERS
          </p>

          <h1 className="text-5xl font-bold text-white">
            Nodes & Staking Made 10x{" "}
            <AnimatePresence mode="wait">
              <motion.span
                key={texts[index]}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5, ease: "easeInOut" }}
                className="text-[#10fc7e]"
              >
                {texts[index]}
              </motion.span>
            </AnimatePresence>
          </h1>
          <p className="mt-4 text-xl text-gray-300">
            Your secure haven for shared masternodes. Benefit from rock-solid
            security, instant deposits, faster withdrawals, and the lowest fees.
            Enjoy uninterrupted earnings with our 99.99% uptime guarantee with
            NodesForest.
          </p>

          {/* Buttons with Icons */}
          <div className="mt-8 flex flex-col space-y-4 md:flex-row md:space-x-12 md:space-y-0">
            <RainbowButton
              onClick={() => router.push("/projects")}
              className="text-md flex items-center space-x-2"
            >
              <span>Explore Nodes</span>
              <AiOutlineArrowRight size={18} />
            </RainbowButton>

          <a href="https://app.nodesforest.com/auth/sign-up/default" className="mt-8 flex flex-col space-y-4 md:flex-row md:space-x-12 md:space-y-0">
             <RainbowButton>
              <FaUser size={18} />
              <span>Create Account</span>
            </RainbowButton>
          </a>
      </div>

        </div>

        {/* Right Side: Two Cards */}
        <div className="relative flex size-full items-center justify-center pr-12 pt-16">
          <div className="relative">
            <motion.div
              className="absolute -top-20 left-12 z-10 h-[25rem] w-72 rounded-lg bg-white p-6 shadow-lg md:-top-28 md:left-24"
              initial="initial"
              whileHover="hover"
              variants={cardHoverEffect}
            >
              <motion.div
                className="mb-3 text-xl font-bold uppercase text-black"
                variants={floatEffect}
              >
                Daily Payouts
              </motion.div>

              <div className="mt-2 flex space-x-2">
                <motion.span
                  className="rounded-full bg-green-500 px-4 py-2 text-xs font-semibold text-white"
                  variants={floatEffect}
                >
                  Automated
                </motion.span>
                <motion.span
                  className="rounded-full bg-purple-500 px-4 py-2 text-xs font-semibold text-white"
                  variants={floatEffect}
                >
                  No Delay
                </motion.span>
              </div>
              <motion.div
                className="relative mt-6 rounded-md bg-black p-4 text-white"
                variants={floatEffect}
              >
                <pre className="overflow-hidden text-ellipsis whitespace-pre-wrap text-sm">
                  <code>{`const DeployNode = (TxHash) => {\n  if (collateral = X) {\n    console.log("Success");\n  } else {\n    throw new Error("Failed");\n  }\n};`}</code>
                </pre>
              </motion.div>
            </motion.div>

            <motion.div
              className="relative z-20 h-[25rem] w-72 rounded-lg bg-white p-6 shadow-lg"
              initial="initial"
              whileHover="hover"
              variants={cardHoverEffect}
            >
              <motion.div
                className="mb-3 text-xl font-bold uppercase text-black"
                variants={floatEffect}
              >
                Easy to Use
              </motion.div>

              <div className="mt-2 flex space-x-2">
                <motion.span
                  className="rounded-full bg-red-500 px-4 py-2 text-xs font-semibold text-white"
                  variants={floatEffect}
                >
                  Straight Forward
                </motion.span>
                <motion.span
                  className="rounded-full bg-blue-500 px-4 py-2 text-xs font-semibold text-white"
                  variants={floatEffect}
                >
                  Hustle Free
                </motion.span>
              </div>
              <motion.div
                className="relative mt-6 rounded-md bg-black p-4 text-white"
                variants={floatEffect}
              >
                <pre className="overflow-hidden text-ellipsis whitespace-pre-wrap text-sm">
                  <code>{`const DeployNode = (TxHash) => {\n  if (collateral = X) {\n    console.log("Success");\n  } else {\n    throw new Error("Failed");\n  }\n};`}</code>
                </pre>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};
