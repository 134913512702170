"use client";
import React from "react";
import { MacbookScroll } from "../ui/macbook-scroll"; // Import the existing MacbookScroll component
import { motion } from "framer-motion";

const Macbook = () => {
  return (
    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden bg-black py-16">
      {" "}
      {/* Updated to dark background */}
      {/* Lamp Effect Section */}
      <div className="relative z-10 mt-32 flex size-full flex-col items-center justify-center">
        <div className="relative isolate z-0 flex w-full scale-y-125 items-center justify-center">
          <motion.div
            initial={{ opacity: 0.5, width: "15rem" }}
            whileInView={{ opacity: 1, width: "30rem" }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: "easeInOut",
            }}
            style={{
              backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
            }}
            className="bg-gradient-conic absolute inset-auto right-1/2 h-56 w-[30rem] overflow-visible from-green-500 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]" // Changed to green
          >
            <div className="absolute bottom-0 left-0 z-20 h-40 w-full bg-black [mask-image:linear-gradient(to_top,white,transparent)]" />
            <div className="absolute bottom-0 left-0 z-20 h-full w-40 bg-black [mask-image:linear-gradient(to_right,white,transparent)]" />
          </motion.div>
          <motion.div
            initial={{ opacity: 0.5, width: "15rem" }}
            whileInView={{ opacity: 1, width: "30rem" }}
            transition={{
              delay: 0.3,
              duration: 0.8,
              ease: "easeInOut",
            }}
            style={{
              backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
            }}
            className="bg-gradient-conic absolute inset-auto left-1/2 h-56 w-[30rem] from-transparent via-transparent to-green-500 text-white [--conic-position:from_290deg_at_center_top]" // Changed to green
          >
            <div className="absolute bottom-0 right-0 z-20 h-full w-40 bg-black [mask-image:linear-gradient(to_left,white,transparent)]" />
            <div className="absolute bottom-0 right-0 z-20 h-40 w-full bg-black [mask-image:linear-gradient(to_top,white,transparent)]" />
          </motion.div>
          <div className="absolute top-1/2 h-48 w-full translate-y-12 scale-x-150 bg-black blur-2xl"></div>
        </div>
        <motion.h1
          initial={{ opacity: 0.5, y: 100 }}
          whileInView={{ opacity: 0.95, y: 2 }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="mt-8 bg-gradient-to-br from-green-300 to-green-500 bg-clip-text py-4 text-center text-4xl font-medium tracking-tight text-transparent md:text-7xl"
        >
          Your masternode <br /> is our responsibility!
        </motion.h1>
      </div>
      {/* Macbook Scroll Effect */}
      <MacbookScroll
        src="/screen.png" // Replace this with the image you want to show on the MacBook screen
        showGradient={true}
        badge={
          <span className="rounded-full bg-green-500 px-2 py-1 text-xs text-white">
            New Feature
          </span>
        }
      />
    </div>
  );
};

export default Macbook;
