"use client";
import React from "react";
import Marquee from "../ui/marquee"; // Import the existing Marquee component
import { SparklesCore } from "../ui/sparkles"; // Import the SparklesCore component
import { HeroHighlight, Highlight } from "../ui/hero-highlight"; // Import the HeroHighlight component
import { motion } from "framer-motion"; // Import framer-motion for animations
import Image from "next/image"; // Import Image from next/image

const reviews = [
  {
    name: "AlderGate",
    username: "@Alder_Gate",
    body: "Best performing shared nodes platform in the crypto space.",
    img: "https://pbs.twimg.com/profile_images/1844056410536955904/jEUatciY_400x400.jpg",
  },
  {
    name: "Army of Web3",
    username: "@ArmyofWeb3",
    body: "A reliable sharednode platform with daily payouts. This is amazing.",
    img: "https://pbs.twimg.com/profile_images/1837253583235903488/rbE30p3N_400x400.jpg",
  },
  {
    name: "CryptoBells🏋🏽‍♀️ 𐤊",
    username: "@Mbj444",
    body: "I'm at a loss for words. This shared MN platform works great.",
    img: "https://pbs.twimg.com/profile_images/1841418204578512896/cf4Ucyu1_400x400.jpg",
  },
  {
    name: "SANTOS",
    username: "@SantosCryptoMAN",
    body: "NodesForest is on the road to be best platform in the niche, no bugs just profits.",
    img: "https://pbs.twimg.com/profile_images/1681012434650136576/4Tl4pxYg_400x400.jpg",
  },
  {
    name: "CryptOG",
    username: "@realhus2",
    body: "Shared nodes platform is just fire. Well done team for this amazing platform as promised.",
    img: "https://pbs.twimg.com/profile_images/1518900336852881409/C_GihOqs_400x400.jpg",
  },
  {
    name: "Jose Moragon",
    username: "@JoseMoragon2",
    body: "Buy $HOOT and enjoy rewards in NodesForest!! Join our family.",
    img: "https://pbs.twimg.com/profile_images/1493248856301133831/TOZiN0Ty_400x400.jpg",
  },
  {
    name: "Jr",
    username: "@joradico4",
    body: "Take advantage of the great work of the team behind HootChain.",
    img: "https://pbs.twimg.com/profile_images/1830702657121468416/21fZR9R2_400x400.jpg",
  },
  {
    name: "Rafol",
    username: "@rafol19620",
    body: "Can't stop using it! Now updated with more features, much safer and with simple steps.",
    img: "https://pbs.twimg.com/profile_images/1792991887780937729/4zT7orLX_400x400.jpg",
  },
];

const firstRow = reviews.slice(0, reviews.length / 2);
const secondRow = reviews.slice(reviews.length / 2);

const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure className="relative w-64 cursor-pointer overflow-hidden rounded-xl border bg-gray-900/[.6] p-4 hover:bg-gray-900/[.8] dark:border-gray-50/[.1] dark:bg-gray-50/[.1] dark:hover:bg-gray-50/[.15]">
      <div className="flex flex-row items-center gap-2">
        <Image
          className="rounded-full"
          src={img}
          alt={name}
          width={32}
          height={32}
          quality={90} // Optional: Set image quality
          priority={true} // Optional: Critical for LCP
        />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium text-white/60">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm text-white dark:text-gray-300">
        {body}
      </blockquote>
    </figure>
  );
};

const Reviews = () => {
  const bounceUp = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 150,
        damping: 20,
        duration: 0.5,
      },
    },
  };

  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden bg-black pb-48 pt-32 md:shadow-xl">
      {/* Sparkles Background */}
      <SparklesCore
        className="absolute inset-0"
        background="transparent"
        particleSize={0.1} // Smaller particles
        particleDensity={5} // Fewer particles
        particleColor="#ffffff" // White color for particles
      />

      {/* Heading Section with HeroHighlight and bounce animation */}
      <HeroHighlight containerClassName="py-4">
        <motion.h2
          className="text-center text-3xl font-bold text-white md:text-5xl"
          variants={bounceUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }} // Trigger when 50% of the section is visible
        >
          What People Are Saying
        </motion.h2>
        <motion.h2
          className="mt-3 text-center text-3xl font-bold text-white md:text-5xl"
          variants={bounceUp}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
        >
          <Highlight className="text-silver-500">About NodesForest</Highlight>
        </motion.h2>
      </HeroHighlight>

      {/* First Row of Reviews */}
      <Marquee pauseOnHover={true} className="[--duration:20s]">
        {firstRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>

      {/* Second Row of Reviews (Reversed Direction) */}
      <Marquee
        reverse={true}
        pauseOnHover={true}
        className="pb-10 [--duration:20s]"
      >
        {secondRow.map((review) => (
          <ReviewCard key={review.username} {...review} />
        ))}
      </Marquee>

      {/* Gradient Effect for Left and Right Edges */}
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-black"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-black"></div>
    </div>
  );
};

export default Reviews;
