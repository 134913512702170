"use client";
import React, { useEffect, useState } from "react";
import { StarsBackground } from "../ui/stars-background";
import { ShootingStars } from "../ui/shooting-stars";
import { cn } from "@/lib/utils";
import { TypewriterEffect } from "../ui/typewriter-effect"; // Import the TypewriterEffect component
import { motion } from "framer-motion"; // Import motion for animations
import {
  IconAdjustmentsBolt,
  IconCloud,
  IconCurrencyDollar,
  IconEaseInOut,
  IconHeart,
  IconHelp,
  IconRouteAltLeft,
  IconTerminal2,
} from "@tabler/icons-react";

export function InfoSection() {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
            observer.disconnect(); // Stop observing once in view
          }
        });
      },
      { threshold: 0.1 },
    );

    const section = document.getElementById("features-section");
    if (section) observer.observe(section);

    return () => observer.disconnect();
  }, []);

  const features = [
    {
      title: "Built for the Masses",
      description:
        "Built for Crypto enthusiasts, dreamers, thinkers and doers.",
      icon: (
        <IconTerminal2 className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "Ease of use",
      description:
        "As simple as clicking a button, as affordable as a cup of coffee.",
      icon: (
        <IconEaseInOut className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "Low fees like no other",
      description:
        "Our fees are best in the market. No cap, no lock, no hidden charges.",
      icon: (
        <IconCurrencyDollar className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "100% Uptime guarantee",
      description:
        "Trust us to keep your masternodes running smoothly, with no downtime.",
      icon: (
        <IconCloud className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "Extensible Architecture",
      description:
        "You can simply use same account to access all other future services.",
      icon: (
        <IconRouteAltLeft className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "24/7 Customer Support",
      description:
        "We are available a 100% of the time. Ressolve all your queries",
      icon: (
        <IconHelp className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "Auto Payments Daily",
      description:
        "Get rewards sent to your external wallet, everyday at 00:00 UTC.",
      icon: (
        <IconAdjustmentsBolt className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
    {
      title: "Secure and Reliable",
      description: "Security is at the forefront of everything we do.",
      icon: (
        <IconHeart className="text-green-500 transition-transform duration-300 group-hover/feature:-translate-y-2 group-hover/feature:scale-125" />
      ), // Zoom and upward move
    },
  ];

  const words = [
    {
      text: "Why",
      className: "text-white",
    },
    {
      text: "Choose",
      className: "text-white",
    },
    {
      text: "NodesForest?",
      className: "text-green-500", // NodesForest in green
    },
  ];

  const featureAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.2,
        duration: 0.6,
      },
    }),
  };

  const paragraphAnimation = {
    hidden: { opacity: 0, y: 25 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  return (
    <section
      id="features-section"
      className="relative min-h-screen overflow-hidden bg-black py-16"
    >
      {/* Stars Background */}
      <StarsBackground
        starDensity={0.0002}
        twinkleProbability={0.7}
        minTwinkleSpeed={0.5}
        maxTwinkleSpeed={1}
        className="z-0"
      />

      {/* Shooting Stars */}
      <ShootingStars
        minSpeed={10}
        maxSpeed={30}
        minDelay={1000}
        maxDelay={4000}
        starColor="#FFD700"
        trailColor="#00BFFF"
        className="z-10"
      />

      {/* Typewriter Heading */}
      <div className="relative z-20 mb-4 text-center">
        <TypewriterEffect
          words={words}
          className="text-3xl font-bold sm:text-4xl md:text-5xl lg:text-6xl"
        />
      </div>

      {/* New Paragraph Text with animation */}
      <motion.div
        className="relative z-20 mx-auto mb-6 max-w-5xl p-2 text-center text-lg text-gray-300"
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={paragraphAnimation}
      >
        You can easily start investing in shared masternodes on NodesForest. Our
        innovative platform offers a user-friendly way for newbies and small
        investors to participate in the masternode ecosystem and diversify their
        cryptocurrency investments.
      </motion.div>

      <div className="relative z-20 mx-auto grid max-w-7xl grid-cols-1 py-10 md:grid-cols-2 lg:grid-cols-4">
        {features.map((feature, index) => (
          <motion.div
            key={feature.title}
            custom={index}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={featureAnimation}
          >
            <Feature key={feature.title} {...feature} index={index} />
          </motion.div>
        ))}
      </div>
    </section>
  );
}

const Feature = ({
  title,
  description,
  icon,
  index,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  index: number;
}) => {
  return (
    <div
      className={cn(
        "group/feature relative flex flex-col py-10 dark:border-neutral-800 lg:border-r",
        (index === 0 || index === 4) && "dark:border-neutral-800 lg:border-l",
        index < 4 && "dark:border-neutral-800 lg:border-b",
      )}
    >
      {index < 4 && (
        <div className="pointer-events-none absolute inset-0 size-full bg-gradient-to-t from-neutral-900 to-transparent opacity-0 transition duration-200 group-hover/feature:opacity-100 dark:from-neutral-700" />
      )}
      {index >= 4 && (
        <div className="pointer-events-none absolute inset-0 size-full bg-gradient-to-b from-neutral-900 to-transparent opacity-0 transition duration-200 group-hover/feature:opacity-100 dark:from-neutral-700" />
      )}
      <div className="relative z-10 mb-4 px-10 text-neutral-600 dark:text-neutral-400">
        {icon}
      </div>
      <div className="relative z-10 mb-2 px-10 text-lg font-bold">
        <div className="absolute inset-y-0 left-0 h-6 w-1 origin-center rounded-r-full bg-neutral-300 transition-all duration-200 group-hover/feature:h-8 group-hover/feature:bg-green-500 dark:bg-neutral-700" />
        <span className="inline-block text-green-500 transition duration-200 group-hover/feature:translate-x-2">
          {title}
        </span>
      </div>
      <p className="relative z-10 max-w-xs px-10 text-sm text-neutral-600 transition duration-200 group-hover/feature:text-white dark:text-neutral-300">
        {description}
      </p>
    </div>
  );
};
