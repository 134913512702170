import(/* webpackMode: "eager", webpackExports: ["HeroSection"] */ "/var/www/html/landing/components/home/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfoSection"] */ "/var/www/html/landing/components/home/InfoSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/landing/components/home/Macbook.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/landing/components/home/Reviews.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/landing/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/landing/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/landing/public/logo.svg");
